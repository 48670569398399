import { navigate } from 'gatsby';
import {CARD_PUBLIC, EXTERNAL_URL, VCARD} from '@helpers/api';
import { makeGetRequest } from '@helpers/requests';
import React, { useState, useEffect } from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { useTheme } from '@mui/styles';
import { Card as CardType } from '@models/card';
import Public from '@components/common/Layouts/Public';
import Loader from '@components/common/Loader';

const ExternalUrl: React.FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
	const [openSnackbar] = useSnackbar();
	const theme = useTheme();
	const [loading, setLoading] = useState<boolean>(true);
	const [externalUrl, setExternalUrl] = useState<any>(null);

	useEffect(() => {
		(async () => {
			try {
				if (!id) {
					// TODO: Redirect user away to a more ideal page than a snackbar error.
					openSnackbar('No External URL was given.', 'error');
					return;
				}

				const { data } = await makeGetRequest(EXTERNAL_URL(id));

				setExternalUrl(data);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve this card.',
						'error'
					);
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (!externalUrl) return;
		navigate(externalUrl?.url);
	}, [externalUrl]);

	return (
		<Public>
			<Loader />
		</Public>
	);
};

const ExternalUrlRouter: React.FC = () => {
	return (
		<Router>
			<ExternalUrl path="/external-url/:id" />
		</Router>
	);
};

export default ExternalUrlRouter;
